<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row" v-if="role != 'admin'">
            <div class="col-md-12">
                    <span class="badge badge-info mr-2">အရွေးစာရင်း ချိန်ညှိမှု အခြေအနေ  => {{tc}} <=> {{c}}</span>
                    <div class="shell">
                        <div class="bar" :style="{ width: completed + '%' }">
                        <span>{{ completed }}%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2" v-if="role != 'admin'">
            <div class="col-md-12">
                    <span class="badge badge-info mr-2">လက်ကျန်စာရင်း ချိန်ညှိမှု အခြေအနေ => {{tp}} <=> {{p}}</span>
                    <div class="shell">
                        <div class="bar-p" :style="{ width: pending + '%' }">
                        <span>{{ pending }}%</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row mt-2">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">အပေါင်စာရင်း အားလုံးပေါင်း</div>
                    <div class="card-body">
                        <p class="card-text">ပစ္စည်းခုရေ: {{mortgage.item}} ခု</p>
                        <p class="card-text">ချေးငွေ: {{mortgage.price}} ကျပ်</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">အရွေးစာရင်း အားလုံးပေါင်း</div>
                    <div class="card-body">
                        <p class="card-text">ပစ္စည်းခုရေ: {{pay.item}} ခု</p>
                        <p class="card-text">ချေးငွေ: {{pay.price}} ကျပ်</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">လက်ကျန်စာရင်း အားလုံးပေါင်း</div>
                    <div class="card-body">
                        <p class="card-text">ပစ္စည်းခုရေ: {{processing.item}} ခု</p>
                        <p class="card-text">ချေးငွေ: {{processing.price}} ကျပ်</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">အပေါင်ဆုံးစာရင်း အားလုံးပေါင်း</div>
                    <div class="card-body">
                        <p class="card-text">ပစ္စည်းခုရေ: {{loss.item}} ခု</p>
                        <p class="card-text">ချေးငွေ: {{loss.price}} ကျပ်</p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row mt-3">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">ယနေ့ အပေါင်စာရင်း</div>
                    <div class="card-body">
                        <p class="card-text">ပစ္စည်းခုရေ: {{todayMortgage.item}} ခု</p>
                        <p class="card-text">ချေးငွေ: {{todayMortgage.price}} ကျပ်</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">ယနေ့ အရွေးစာရင်း</div>
                    <div class="card-body">
                        <p class="card-text">ပစ္စည်းခုရေ: {{todayPay.item}} ခု</p>
                        <p class="card-text">ချေးငွေ: {{todayPay.price}} ကျပ်</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">ဆိုင်ခွဲစာရင်း</div>
                    <div class="card-body">
                        <p class="card-text">{{shop}} ဆိုင်</p><br>
                        <p class="card-text"></p>
                    </div>
                </div>
            </div>
             <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">ပစ္စည်းအမျိုးအစား စာရင်း</div>
                    <div class="card-body">
                        <p class="card-text">ပစ္စည်းခုရေ: {{item}} ခု</p><br>
                        <p class="card-text"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3" v-for="shop in shopList">
            <div class="card-header">{{shop.shop}}</div>
            <div class="card-body text-dark">
                <ul class="list-group list-group-horizontal">
                    <li class="list-group-item" style="font-size: 16px;">
                        ယနေ့ အပေါင်စာရင်း : 
                        <span class="badge badge-primary mr-2">ပစ္စည်းခုရေ {{shop.todayMortgageItem}} ခု</span>
                        <span class="badge badge-info">ချေးငွေ {{shop.todayMortgagePrice}} ကျပ်</span>
                    </li>
                    <li class="list-group-item" style="font-size: 16px;">
                        ယနေ့ အရွေးစာရင်း : 
                        <span class="badge badge-primary  mr-2">ပစ္စည်းခုရေ {{shop.todayPayItem}} ခု</span>
                        <span class="badge badge-info">ချေးငွေ {{shop.todayPayPrice}} ကျပ်</span>
                    </li>
                </ul>
            </div>
        </div>

         <div class="card mt-3" v-for="shop in calcShopList" v-if="role == 'admin'">
            <div class="card-header">{{shop.shop}}</div>
            <div class="card-body text-dark">
                <div class="row">
                    <div class="col-md-12">
                            <span class="badge badge-info mr-2">အရွေးစာရင်း ချိန်ညှိမှု အခြေအနေ  => {{shop.tc}} <=> {{shop.c}}</span>
                            <div class="shell">
                                <div class="bar" :style="{ width: shop.completed + '%' }">
                                <span>{{ shop.completed }}%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                            <span class="badge badge-info mr-2">လက်ကျန်စာရင်း ချိန်ညှိမှု အခြေအနေ => {{shop.tp}} <=> {{shop.p}}</span>
                            <div class="shell">
                                <div class="bar-p" :style="{ width: shop.pending + '%' }">
                                <span>{{ shop.pending }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="my-modal" hide-backdrop hide-footer>
			<template v-slot:modal-title>
				ချိန်ညှိမှု အခြေအနေ
			</template>
			<div class="d-block text-center">
				<p>ချိန်ညှိမှု အခြေအနေ ပြီးစီးခဲ့ ပြီးဖြစ်ပါသည်။</p>
			</div>
		</b-modal>
        <a class="float-filter" to="" @click="calcComplete()" v-if="role != 'admin'">
            <i class="fas fa-sync-alt my-float" style="padding: 37%;bottom: 50px;"></i>
        </a>
        <a class="float" to="" @click="calcPending()" v-if="role != 'admin'">
            <i class="fas fa-sync-alt my-float" style="padding: 37%;" ></i>
        </a>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Printd from 'printd'
    import PureVueChart from 'pure-vue-chart'
    export default {
        name: 'dashboard',
        components: {
            Loading,
            PureVueChart
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                completed: 0,
                pending: 0,
                tc: "",
                c: "",
                tp: "",
                p: "",
                role: "",
                mortgage: [],
                pay: [],
                processing: [],
                loss: [],
                todayMortgage: [],
                todayPay: [],
                shop: "",
                item: "",
                shopList: [],
                calcShopList: []
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                dashboardClothAction: 'dashboardClothAction',
                dashboardClothMortgageAction: 'dashboardClothMortgageAction',
                dashboardCalcCompletedAction: 'dashboardCalcCompletedAction',
                dashboardCalcPendingAction: 'dashboardCalcPendingAction'
            }),
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardClothAction().then(res => {
                    if (res.status == "success") {
                        this.mortgage = res.data.mortgage
                        this.pay = res.data.pay
                        this.processing = res.data.processing
                        this.loss = res.data.loss
                        this.todayMortgage = res.data.todayMortgage
                        this.todayPay = res.data.todayPay
                        this.shop = res.data.shop
                        this.item = res.data.item
                        this.shopList = res.data.shopList
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async dashboardMortgageDetail() {
                this.isLoading = true;
                await this.dashboardClothMortgageAction().then(res => {
                    if (res.status == "success") {
                        this.completed = res.data.completed
                        this.pending = res.data.pending
                        this.tc = res.data.tc
                        this.c = res.data.c
                        this.tp = res.data.tp
                        this.p = res.data.p
                        this.calcShopList = res.data.shopList
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async calcComplete() {
				this.isLoading = true
                if(this.tc == this.c){
                    this.$refs['my-modal'].show()
                    this.isLoading = false
                    return
                }
				await this.dashboardCalcCompletedAction().then(res => {
                    if (res.status == "success") {
                        this.dashboardDetail()
                        this.dashboardMortgageDetail()
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
			},
            async calcPending() {
				this.isLoading = true
                 if(this.tp == this.p){
                    this.$refs['my-modal'].show()
                    this.isLoading = false
                    return
                }
				await this.dashboardCalcPendingAction().then(res => {
                    if (res.status == "success") {
                        this.dashboardDetail()
                        this.dashboardMortgageDetail()
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
			},
        },
        async mounted() {
            this.dashboardDetail()
            this.dashboardMortgageDetail()
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.role = user.role
        }
    }
</script>


<style scoped lang="scss">
.shell {
    height: 25px;
    width: 100%;
    border: 1px solid #aaa;
    border-radius: 13px;
    padding: 1px;
}

.bar {
  background: #007bff;
  height: 20px;
  width: 15px;
  border-radius: 9px;
  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em
  }
}

.bar-p {
  background: #0C9;
  height: 20px;
  width: 15px;
  border-radius: 9px;
  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em
  }
}
</style>